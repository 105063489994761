"use client";

import React, { useState, useTransition } from "react";
import { Alert, Box, Grid, TextField } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "next/navigation";
import { z } from "zod";
import { loginSchema } from "@/src/app/(client)/_features/schemas/login";
import { zodResolver } from "@hookform/resolvers/zod";
import { login } from "../actions/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { LogInIcon } from "lucide-react";

export default function LoginForm() {
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl");
  const [error, setError] = useState<string | undefined>("");
  const [success, setSuccess] = useState<string | undefined>("");
  const [isPending, startTransition] = useTransition();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<z.infer<typeof loginSchema>> = async (
    values
  ) => {
    setError("");
    startTransition(() => {
      login(values, callbackUrl)
        .then((data) => {
          if (data?.error) {
            setError(data?.error);
          }
        })
        .catch((err) => setError(err));
    });
  };
  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Email"
              {...register("email")}
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Password"
              {...register("password")}
              fullWidth
              error={!!errors.password}
              helperText={errors.password?.message}
              type="password"
            />
          </Grid>
          <Grid sx={{ width: "100%" }} item xs={12}>
            {error && (
              <Alert sx={{ width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <LoadingButton
              startIcon={<LogInIcon size={18} />}
              fullWidth
              loading={isPending}
              disabled={isPending}
              type="submit"
              variant="contained"
              sx={{
                fontWeight: "bold",
                bgcolor: "#4B3621",
                "&:hover": {
                  bgcolor: "#4B3621",
                },
              }}
            >
              Sign in
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
